import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {fetchUtils} from "../common/utils/fetch_util";

interface FormData {
  name: string;
  surname: string;
  email: string;
  tel: string;
  message: string;
}

export const Section2: React.FC = () => {
  const {t} = useTranslation();
  const [response, setResponse] = useState<string | undefined>(undefined);
  const {register, handleSubmit, watch, errors} = useForm<FormData>();
  const onSubmit = handleSubmit(({name, surname, email, tel, message}) => {
    fetchUtils.postData(`${process.env.GATSBY_BACKEND}/contact`, {
      name: name,
      surname: surname,
      email: email,
      tel: tel,
      message: message
    }).then(response => {
      if (response.ok) setResponse('success');
      else setResponse('error');
    });
  });

  return (
      <section className="contact-form">
        <h2>{t('contact.section2.heading')}</h2>
        {
          response && (
            <div className="contact-form__response">
              <span>{t(`contact.section2.form.${response}`)}</span>
            </div>
          )
        }
        <form onSubmit={onSubmit}>
          {/*TODO: add input fields*/}
          <input name="name" type="text" placeholder={t('contact.section2.form.name')} ref={register({required: true})} required/>
          <input name="surname" type="text" placeholder={t('contact.section2.form.surname')} ref={register({required: true})} required/>
          <input name="email" type="text" placeholder={t('contact.section2.form.email')} ref={register({required: true})} required/>
          <input name="tel" type="text" placeholder={t('contact.section2.form.tel')} ref={register}/>
          <textarea name="message" placeholder={t('contact.section2.form.message')} ref={register({required: true})} required/>

          <button className="button primary" type="submit">{t('contact.section2.form.button')}</button>
        </form>
       
      </section>
  )
}
