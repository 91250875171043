import React from "react";
import {Section1} from "../components/contact/section1";
import {Section2} from "../components/contact/section2";
import {NormalLayout} from "../components/layouts/normal_layout";
import {SEO} from "../components/seo";

const Contact: React.FC = () => {

  return (
      <NormalLayout type="contact">
        <SEO title="Contact Us | UVX Solutions" description="Contact Us | UVX Solutions" />
        <Section1 />
        <Section2 />
      </NormalLayout>
  )
}

export default Contact;